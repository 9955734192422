@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


body{
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.App{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.github{
    display: flex;
    justify-content: flex-end;
}

.github p{
    margin-bottom: 0;
    margin-top: 2px;
}

.Board{
    display: flex;
    justify-content: center;
}

.controls{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    flex-wrap: wrap;
}

.controls button, .controls select{
    margin: 10px;
    border:0;
    cursor: pointer;
    padding: 15px 20px;
}

.controls select{
    background-color: rgb(215, 249, 248);
    border-radius: 10%;
}

button{
    border-radius: 10%;
    color: #ffffff;
    font-weight: 900;
    font-family: Montserrat;
    font-size: 16px;
}

#lock{
    background-color: rgb(255, 110, 150);
}

#lock:hover{
    background-color: rgb(230, 85, 125);
}

#shuffle{
    background-color: rgb(171, 156, 209);
}

#shuffle:hover{
    background-color: rgb(146, 131, 186);
}

#copylink{
    background-color: rgb(162, 222, 189);
}

#copylink:hover{
    background-color: rgb(137, 197, 164);
}
.title{
    font-family: Caveat;
    font-weight: 900;
    text-align: center;
    font-size: 4rem;
}

.square-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 750px;
    width: 100%;
  }
  
  .square {
    position: relative;
    border: 1px solid;
    box-sizing: border-box;
  }
  
  .square::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  
  .square .content {
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p{
      text-align: center;
      padding: 5px;
  }

td{
    width: 150px;
    height: 150px;
    text-align: center;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
}
.checked{
    background-color: rgb(215, 249, 248);
}

.winning{
    background-color: rgb(229, 212, 239);
}

.cell{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

table{
    border-collapse: separate;
}

table, td{
    border: solid 1px rgb(0, 0, 0);
    border-collapse: collapse;
}

textarea{
    height: 95%;
    width: 95%;
    resize: none;
    font-size: 16px;
    font-weight: 600;
}

.title-container{
    display: flex;
    justify-content: center;
}

#editable-title{
    font-size: 50px;
    font-family: Caveat;
    font-weight: 900;
    text-align: center;
    font-size: 4rem;
    border: none;
}

@media (max-height: 720px){
    .square-container{
        max-width: 430px;
    }
    p, textarea{
        font-size: 12px;
    }
}

@media (max-width: 650px){
    p, textarea{
        font-size: 12px;
    }
}

@media (max-width: 490px){
    p, textarea{
        font-size:  12px;
    }
    button, select{
        font-size: 12px;
    }
}

@media print {
    button, select{ 
        display: none;
    }
    .github{
        display: none;
    }
}
